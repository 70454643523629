// Import Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
// Variable global
import { environment } from 'src/environments/environment';
// Model
import { Page } from 'src/app/shared/models/page';
import { Cmm } from 'src/app/shared/models/cmm';
// Service
import { ToolsService } from 'src/app/core/services/tools/tools.service';

@Injectable({
  providedIn: 'root'
})
export class CmmsService {

  private server         = environment.api.server;
  private api            = environment.api.cmm.url;
  private version        = environment.api.cmm.version;
  private httpOptions    = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
  private httpOptionsResponse = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' as 'body'};

  constructor(private http: HttpClient, private toolsService: ToolsService) { }

  public addCmm(
    codeCmm: string,
    codeEquipmentDash: string,
    model: string,
    designationEquipment: string,
    idState: number,
    isProd: number,
    revision: number,
    dateRevision: Date,
    idFactory: number
  ): Observable<HttpResponse<Cmm>>
  {
    let url = `${this.server}${this.api}/${this.version}/addcmm`;
    return this.http.post<HttpResponse<Cmm>>
    (
      url,
      {codeCmm, codeEquipmentDash, model, designationEquipment, idState, isProd, revision, dateRevision, idFactory},
      this.httpOptionsResponse
    ).pipe(catchError(this.toolsService.errorManager<HttpResponse<Cmm>>('addCmm', null)));
  }

  public updateCmm(
    idCmm: number,
    codeCmm: string,
    codeEquipmentDash: string,
    model: string,
    designationEquipment: string,
    idState: number,
    isProd: number,
    revision: number,
    dateRevision: Date,
    idFactory: number
  ): Observable<HttpResponse<Cmm>>
  {
    let url = `${this.server}${this.api}/${this.version}/updatecmm`;
    return this.http.post<HttpResponse<Cmm>>(
      url, {idCmm, codeCmm, codeEquipmentDash, model, designationEquipment, idState, isProd, revision, dateRevision, idFactory},
      this.httpOptionsResponse
    ).pipe(catchError(this.toolsService.errorManager<HttpResponse<Cmm>>('updatecmm', null)));
  }

  public getCmmDetail(idCmm: number): Observable<Cmm>
  {
    let url = `${this.server}${this.api}/${this.version}/getcmmdetail`;
    return this.http.post<Cmm>(url, {idCmm}, this.httpOptions).pipe(catchError(this.toolsService.errorManager<Cmm>('getCmmDetail', null)));
  }

  public checkCodeCmmFactory(idFactory: number, codeCmm: string, idCmm?: number): Observable<boolean>
  {
    let url = `${this.server}${this.api}/${this.version}/checkcodecmmfactory`;
    return this.http.post<boolean>(
      url,
      {idFactory, codeCmm, idCmm},
      this.httpOptions
    ).pipe(catchError(this.toolsService.errorManager<boolean>('checkCodeCmmFactory', null)));
  }

  public checkCodeEquipmentFactory(idFactory: number, codeEquipmentDash: string, idCmm?: number): Observable<boolean>
  {
    let url = `${this.server}${this.api}/${this.version}/checkcodeequipmentfactory`;
    return this.http.post<boolean>(
      url,
      {idFactory, codeEquipmentDash, idCmm},
      this.httpOptions
    ).pipe(catchError(this.toolsService.errorManager<boolean>('checkcodeequipmentfactory', null)));
  }

  public searchCmm(searchTerm: string, sort: string, order: string, page: number, itemsPerPage: number): Observable<Page<Cmm>> {
    let url = `${this.server}${this.api}/${this.version}/searchcmm`;
    return this.http.post<Page<Cmm>>(url, { searchTerm, sort, order, page, itemsPerPage }, this.httpOptions)
    .pipe(catchError(this.toolsService.errorManager<Page<Cmm>>('searchcmm', null)));
  }

  public searchCmmVariante(
    codeEquipmentDash: string,
    searchTerm: string,
    sort: string,
    order: string,
    page: number,
    itemsPerPage: number,
    idCmm: number
  ): Observable<Page<Cmm>> {
    let url = `${this.server}${this.api}/${this.version}/searchcmmvariante`;
    return this.http.post<Page<Cmm>>(url, { codeEquipmentDash, searchTerm, sort, order, page, itemsPerPage, idCmm }, this.httpOptions)
    .pipe(catchError(this.toolsService.errorManager<Page<Cmm>>('searchcmmvariante', null)));
  }

  public findCmms(code: string, limit?: number): Observable<Cmm[]>
  {
    let url = `${this.server}${this.api}/${this.version}/findcmms`;
    return this.http.post<Cmm[]>(
      url,
      {code, limit},
      this.httpOptions
    ).pipe(catchError(this.toolsService.errorManager<Cmm[]>('findcmms', null)));
  }

  public getCmmForDashPn(pnDash: string): Observable<HttpResponse<Cmm[]>> {
    let url = `${this.server}${this.api}/${this.version}/getcmmfordashpn`;
    return this.http.post<HttpResponse<Cmm[]>>(url, { pnDash }, this.httpOptionsResponse)
    .pipe(catchError(this.toolsService.errorManager<HttpResponse<Cmm[]>>('getcmmfordashpn', null)));
  }



}

