// Import Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { of as observableOf } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as io from 'socket.io-client';
// Variable global
import { environment } from 'src/environments/environment';
import { repairCardConst } from 'src//app/shared/static/const/repair-card-const';
// Model
import { RepairCard } from 'src/app/shared/models/repair-card';
import { RepairCardChange } from 'src/app/shared/models/repair-card-change';
import { Basket } from 'src/app/shared/models/basket';
import { BasketItems } from 'src/app/shared/models/basket-item';
import { Figure } from 'src/app/shared/models/figure';
import { Kit } from 'src/app/shared/models/kit';
import { Page } from 'src/app/shared/models/page';
import { PageSimple } from 'src/app/shared/models/page-simple';
import { Nomenclature } from 'src/app/shared/models/nomenclature';
import { Order } from 'src/app/shared/models/order';
// Service
import { ToolsService } from 'src/app/core/services/tools/tools.service';
import { Amendment } from 'src/app/shared/models/amendment';
import { quoteConst } from 'src/app/shared/static/const/quote-const';
@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private server = environment.api.server;
  private api = environment.api.order.url;
  private version = environment.api.order.version;
  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  private httpOptionsResponse = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: 'response' as 'body',
  };
  private httpMediaResponse = { observe: 'response' as 'body' };
  private userBasket: BehaviorSubject<Basket>;

  private emitChangeSource: Subject<number>;
  changeEmitted$: Observable<number>;
  private orderUpdated: BehaviorSubject<number>;
  private socket: io;
  // Url du socket de l'application permettant de notifier un administrateur de la mise à jour d'un ou plusieurs devis
  private socketServer = environment.urlSocket;
  constructor(private http: HttpClient, private toolsService: ToolsService) {
    this.orderUpdated = new BehaviorSubject<number>(0);
  }

  public getQuoteSample(): Observable<RepairCard> {
    let url = `${this.server}${this.api}/${this.version}/getquotesample`;
    return this.http
      .get<RepairCard>(url, this.httpOptions)
      .pipe(
        catchError(
          this.toolsService.errorManager<RepairCard>('getquotesample', null)
        )
      );
  }

  public downloadRepairCard(file: string, fileName: string): void {
    const blob = this.toolsService.dataURItoBlob(
      file,
      repairCardConst.fileType
    );
    const url = window.URL.createObjectURL(blob);
    saveAs(url, fileName);
  }

  public convertFile(file: string): Blob {
    return this.toolsService.dataURItoBlob(file, repairCardConst.fileType);
  }

  public downloadFile(file: Blob, fileName: string): void {
    saveAs(window.URL.createObjectURL(file), fileName);
  }

  public updateQuoteReference(file: File): Observable<RepairCardChange> {
    let quoteFile = new FormData();
    quoteFile.append('quoteFile', file, file.name);

    let url = `${this.server}${this.api}/${this.version}/updatequotereference`;
    return this.http
      .post<RepairCardChange>(url, quoteFile)
      .pipe(
        catchError(
          this.toolsService.errorManager<RepairCardChange>(
            'updatequotereference',
            null
          )
        )
      );
  }

  public getBasket() {
    return this.userBasket;
  }

  public getOrderUpdated() {
    return false;
  }

  public setOrderUpdated() {
    this.orderUpdated = new BehaviorSubject<number>(0);
    this.changeEmitted$ = this.orderUpdated.asObservable();
  }

  public addOrderUpdated() {
    this.orderUpdated.next(this.orderUpdated.getValue() + 1);
  }

  public resetOrderUpdated() {
    this.orderUpdated.next(0);
  }

  public getBasketForUser(): Observable<Basket> {
    let url = `${this.server}${this.api}/${this.version}/getbasketinprogessforuser`;
    return this.http
      .get<Basket>(url, this.httpOptions)
      .pipe(
        catchError(
          this.toolsService.errorManager<Basket>(
            'getbasketinprogessforuser',
            null
          )
        )
      );
  }

  public setBasket(): void {
    this.getBasketForUser().subscribe((data) => {
      this.userBasket = new BehaviorSubject<Basket>(data);
    });
  }

  public saveBasket(basket: Basket): void {
    this.userBasket = new BehaviorSubject<Basket>(basket);
  }

  public createBasket(
    pnIn: string,
    pnOut: string,
    serialNumber: string,
    workOrder: string,
    idCmm: number,
    orderType: number,
    arrayIdAmendement?: Amendment[]
  ): Observable<Basket> {
    let url = `${this.server}${this.api}/${this.version}/createbasket`;
    return this.http
      .post<Basket>(
        url,
        {
          pnIn,
          pnOut,
          serialNumber,
          workOrder,
          idCmm,
          orderType,
          arrayIdAmendement,
        },
        this.httpOptions
      )
      .pipe(
        catchError(this.toolsService.errorManager<Basket>('createBasket', null))
      );
  }

  public deleteBasket(idOrder: number): Observable<HttpResponse<boolean>> {
    let url = `${this.server}${this.api}/${this.version}/deletebasket`;
    return this.http
      .post<HttpResponse<boolean>>(url, { idOrder }, this.httpOptionsResponse)
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<boolean>>(
            'deletebasket',
            null
          )
        )
      );
  }

  public deleteBasketByAdmin(
    idOrder: number
  ): Observable<HttpResponse<boolean>> {
    let url = `${this.server}${this.api}/${this.version}/deletebasketadmin`;
    return this.http
      .post<HttpResponse<boolean>>(url, { idOrder }, this.httpOptionsResponse)
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<boolean>>(
            'deletebasketadmin',
            null
          )
        )
      );
  }

  public cleanBasket(idOrder): Observable<boolean> {
    let url = `${this.server}${this.api}/${this.version}/cleanbasket`;
    return this.http
      .post<boolean>(url, { idOrder }, this.httpOptions)
      .pipe(
        catchError(this.toolsService.errorManager<boolean>('cleanbasket', null))
      );
  }

  public getListItems(idOrder: number): Observable<HttpResponse<Basket>> {
    let url = `${this.server}${this.api}/${this.version}/getlistitems`;
    const itemsPerPage = 10;
    const page = 0;
    return this.http
      .post<HttpResponse<Basket>>(
        url,
        { idOrder, itemsPerPage, page },
        this.httpOptions
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<Basket>>(
            'getListItems',
            null
          )
        )
      );
  }

  public getListkits(
    idOrder: number,
    itemsPerPage: number,
    page: number
  ): Observable<HttpResponse<Page<Kit>>> {
    let url = `${this.server}${this.api}/${this.version}/getlistkits`;
    return this.http
      .post<HttpResponse<Page<Kit>>>(
        url,
        { idOrder, itemsPerPage, page },
        this.httpOptionsResponse
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<Page<Kit>>>(
            'getlistkits',
            null
          )
        )
      );
  }

  public getListFigures(
    idOrder: number,
    itemsPerPage: number,
    page: number
  ): Observable<HttpResponse<Page<Figure>>> {
    let url = `${this.server}${this.api}/${this.version}/getlistfigures`;
    return this.http
      .post<HttpResponse<Page<Figure>>>(
        url,
        { idOrder, itemsPerPage, page },
        this.httpOptionsResponse
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<Page<Figure>>>(
            'getlistfigures',
            null
          )
        )
      );
  }

  public getAllFigureForOrder(idOrder: number): Observable<Page<Figure>> {
    let url = `${this.server}${this.api}/${this.version}/getallfigurefororder`;
    return this.http
      .post<Page<Figure>>(url, { idOrder }, this.httpOptions)
      .pipe(
        catchError(
          this.toolsService.errorManager<Page<Figure>>(
            'getAllFigureForOrder',
            null
          )
        )
      );
  }

  public getKitDetailOrder(
    idOrder: number,
    idKit: number,
    itemsPerPage: number,
    page: number
  ): Observable<Page<Nomenclature>> {
    let url = `${this.server}${this.api}/${this.version}/getkitdetailorder`;
    return this.http
      .post<Page<Nomenclature>>(
        url,
        { idOrder, idKit, itemsPerPage, page },
        this.httpOptions
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<Page<Nomenclature>>(
            'getkitdetailorder',
            null
          )
        )
      );
  }

  public addKitTobasket(
    idOrder: number,
    idKit: number,
    quantity: number,
    idReason: number
  ): Observable<HttpResponse<boolean>> {
    let url = `${this.server}${this.api}/${this.version}/addkittobasket`;
    return this.http.post<HttpResponse<boolean>>(
      url,
      { idOrder, idKit, quantity, idReason },
      this.httpOptionsResponse
    );
  }

  public getBasketDetail(
    idOrder: number,
    itemsPerPage: number,
    page: number
  ): Observable<HttpResponse<PageSimple<BasketItems>>> {
    let url = `${this.server}${this.api}/${this.version}/getbasketdetail`;
    return this.http
      .post<HttpResponse<PageSimple<BasketItems>>>(
        url,
        { idOrder, itemsPerPage, page },
        this.httpOptionsResponse
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<PageSimple<BasketItems>>>(
            'getbasketdetail',
            null
          )
        )
      );
  }

  public checkQuantities(
    idOrder: number,
    autoCorrect = false
  ): Observable<boolean> {
    let url = `${this.server}${this.api}/${this.version}/checkOrCorrectMaximumQuantityExceeded`;
    return this.http
      .post<any>(url, { idOrder, autoCorrect }, this.httpOptions)
      .pipe(
        catchError(
          this.toolsService.errorManager<boolean>('controlQuantity', null)
        )
      );
  }

  public validateBasket(idOrder: number): Observable<boolean> {
    let url = `${this.server}${this.api}/${this.version}/validatebasket`;
    return this.http
      .post<boolean>(url, { idOrder }, this.httpOptions)
      .pipe(
        catchError(
          this.toolsService.errorManager<boolean>('validatebasket', null)
        )
      );
  }

  public validateBasketByAdmin(
    idOrder: number
  ): Observable<HttpResponse<boolean>> {
    let url = `${this.server}${this.api}/${this.version}/validatebasketadmin`;
    return this.http
      .post<HttpResponse<boolean>>(url, { idOrder }, this.httpOptionsResponse)
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<boolean>>(
            'validatebasketadmin',
            null
          )
        )
      );
  }

  public generateOrder(idOrder: number): Observable<HttpResponse<any>> {
    let url = `${this.server}${this.api}/${this.version}/generateorder`;
    return this.http
      .post<HttpResponse<any>>(url, { idOrder }, this.httpMediaResponse)
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<any>>(
            'generateorder',
            null
          )
        )
      );
  }

  public generateOrderAgain(idOrder: number): Observable<HttpResponse<any>> {
    let url = `${this.server}${this.api}/${this.version}/generateorderagain`;
    return this.http
      .post<HttpResponse<any>>(url, { idOrder }, this.httpMediaResponse)
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<any>>(
            'generateOrderAgain',
            null
          )
        )
      );
  }

  public getListItemsForFigure(
    idOrder: number,
    idDrawing: number,
    itemsPerPage: number,
    page: number
  ): Observable<Page<Nomenclature>> {
    let url = `${this.server}${this.api}/${this.version}/getlistitemsforfigure`;
    return this.http
      .post<Page<Nomenclature>>(
        url,
        { idOrder, idDrawing, itemsPerPage, page },
        this.httpOptions
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<Page<Nomenclature>>(
            'getlistitemsforfigure',
            null
          )
        )
      );
  }

  public searchItemsForFigure(
    idOrder: number,
    idDrawing: number,
    idsNomenclature: number[] = null,
    sort: string,
    order: string,
    page: number,
    itemsPerPage: number,
    searchTerm: string
  ): Observable<HttpResponse<Page<Nomenclature>>> {
    let url = `${this.server}${this.api}/${this.version}/searchitemsforfigure`;
    return this.http
      .post<HttpResponse<Page<Nomenclature>>>(
        url,
        {
          idOrder,
          idDrawing,
          idsNomenclature,
          sort,
          order,
          page,
          itemsPerPage,
          searchTerm,
        },
        this.httpOptionsResponse
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<Page<Nomenclature>>>(
            'searchitemsforfigure',
            null
          )
        )
      );
  }

  public addItemToBasket(
    idOrder: number,
    idNomenclatureItem: number,
    quantity: number,
    idReason: number,
    idKit?: number
  ): Observable<HttpResponse<boolean>> {
    let url = `${this.server}${this.api}/${this.version}/additemtobasket`;
    return this.http
      .post<HttpResponse<boolean>>(
        url,
        { idOrder, idNomenclatureItem, quantity, idReason, idKit },
        this.httpOptionsResponse
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<boolean>>(
            'additemtobasket',
            null
          )
        )
      );
  }

  public updateBasketItem(
    idOrder: number,
    idNomenclatureItem: number,
    quantity: number,
    idReason: number,
    idKit?: number
  ): Observable<HttpResponse<boolean>> {
    let url = `${this.server}${this.api}/${this.version}/updatebasketitem`;
    return this.http
      .post<HttpResponse<boolean>>(
        url,
        { idOrder, idNomenclatureItem, quantity, idReason, idKit },
        this.httpOptionsResponse
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<boolean>>(
            'updatebasketitem',
            null
          )
        )
      );
  }

  public removeItemFromBasket(
    idOrder: number,
    idNomenclatureItem: number
  ): Observable<HttpResponse<boolean>> {
    let url = `${this.server}${this.api}/${this.version}/removeitemfrombasket`;
    return this.http
      .post<HttpResponse<boolean>>(
        url,
        { idOrder, idNomenclatureItem },
        this.httpOptionsResponse
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<boolean>>(
            'removeitemfrombasket',
            null
          )
        )
      );
  }

  public removeKitFromBasket(
    idOrder: number,
    idKit: number
  ): Observable<HttpResponse<boolean>> {
    let url = `${this.server}${this.api}/${this.version}/removeKitFromBasket`;
    return this.http
      .post<HttpResponse<boolean>>(
        url,
        { idOrder, idKit },
        this.httpOptionsResponse
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<boolean>>(
            'removeKitFromBasket',
            null
          )
        )
      );
  }

  public updateBasketKit(
    idOrder: number,
    idKit: number,
    quantity: number,
    idReason: number
  ): Observable<HttpResponse<boolean>> {
    let url = `${this.server}${this.api}/${this.version}/updatebasketkit`;
    return this.http
      .post<HttpResponse<boolean>>(
        url,
        { idOrder, idKit, quantity, idReason },
        this.httpOptionsResponse
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<boolean>>(
            'updatebasketkit',
            null
          )
        )
      );
  }

  /*public updateBasketItem(idOrder: number, idNomenclatureItem: number, quantity: number, idReason: number): Observable<boolean> {
    let url = `${this.server}${this.api}/${this.version}/updatebasketitem`;
    return this.http.post<boolean>(url, {idOrder, idNomenclatureItem, quantity, idReason}, this.httpOptions)
    .pipe(catchError(this.toolsService.errorManager<boolean>('updatebasketitem', null)));
  }*/

  public getAllOrderForUser(
    sort: string,
    order: string,
    page: number,
    itemsPerPage: number,
    searchTerm: string
  ): Observable<Page<Order>> {
    let url = `${this.server}${this.api}/${this.version}/getallorderforuser`;
    return this.http
      .post<Page<Order>>(
        url,
        { sort, order, page, itemsPerPage, searchTerm },
        this.httpOptions
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<Page<Order>>(
            'getallorderforuser',
            null
          )
        )
      );
  }

  public searchOrder(
    sort: string,
    order: string,
    page: number,
    itemsPerPage: number,
    searchTerm: string
  ): Observable<Page<Order>> {
    let url = `${this.server}${this.api}/${this.version}/searchorder`;
    return this.http
      .post<Page<Order>>(
        url,
        { sort, order, page, itemsPerPage, searchTerm },
        this.httpOptions
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<Page<Order>>('searchorder', null)
        )
      );
  }

  public generateOrderFileName(order: Order): string {
    return (
      'PN ' +
      order.pnIn +
      ' SN ' +
      order.serialNumber +
      ' (OF' +
      order.workOrder +
      ')'
    );
  }

  closeSocketGetOrderUpdate() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  initSocketGetOrderUpdate() {
    // Initialisation d'un socket permettant à l'administrateur d'être informé de la mise jour des devis
    this.socket = io(`${this.socketServer}`, { secure: true });
    this.setOrderUpdated();
    this.socket.on(quoteConst.eventUpdateQuote, (res) => {
      this.addOrderUpdated();
    });
  }

  getOrderDetail(idOrder: number): Observable<HttpResponse<Order>> {
    let url = `${this.server}${this.api}/${this.version}/getbasketinformation`;
    return this.http.post<HttpResponse<Order>>(
      url,
      { idOrder },
      this.httpOptionsResponse
    );
  }

  // flag : 	1 for physical kit, 2 for logic kit, 3 for unit items
  checkElementInbasket(
    idOrder: number,
    flag: number,
    idKit?: number,
    idNomenclatureItem?: number
  ): Observable<HttpResponse<number>> {
    let url = `${this.server}${this.api}/${this.version}/checkelementinbasket`;
    return this.http
      .post<HttpResponse<number>>(
        url,
        { idOrder, idKit, idNomenclatureItem, flag },
        this.httpOptionsResponse
      )
      .pipe(
        catchError(
          this.toolsService.errorManager<HttpResponse<number>>(
            'checkelementinbasket',
            null
          )
        )
      );
  }

  getListItemsForSearch(
    idOrder: number,
    searchTerm: string,
    itemsPerPage: number,
    page: number
  ): Observable<Page<Nomenclature>> {
    if (typeof searchTerm === 'string') {
      let url = `${this.server}${this.api}/${this.version}/getlistitemsforsearch`;
      return this.http
        .post<Page<Nomenclature>>(
          url,
          { idOrder, searchTerm, itemsPerPage, page },
          this.httpOptions
        )
        .pipe(
          catchError(
            this.toolsService.errorManager<Page<Nomenclature>>(
              'getlistitemsforsearch',
              null
            )
          )
        );
    } else {
      let item: Page<Nomenclature>;
      return observableOf(item);
    }
  }

  getOrderHistoryDetail(idOrder: number): Observable<HttpResponse<Order>> {
    let url = `${this.server}${this.api}/${this.version}/getorderhistodetail`;
    return this.http.post<HttpResponse<Order>>(
      url,
      { idOrder },
      this.httpOptionsResponse
    );
  }
}
