<div class="container-form">
    <form [formGroup]="searchCmmForm" #srhCmmForm="ngForm">
        <mat-form-field class="full-width">
            <mat-label>
                <mat-icon>search</mat-icon> Recherche CMM
            </mat-label>
            <input matInput type="texte" placeholder="Recherche CMM" formControlName="searchCmm"
                [matAutocomplete]="auto" (blur)="checkCmm()">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngIf="isLoading" class="is-loading">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </mat-option>
                <mat-option *ngIf="!isLoading && !searchCmm.pristine && filteredCMM.length === 0" [value]="">
                    Aucun résultat
                </mat-option>
                <ng-container *ngIf="!isLoading">
                    <mat-option *ngFor="let cmm of filteredCMM" [value]="cmm" (onSelectionChange)="selectCmm(cmm)">
                        <div class="container-opt">
                            <span>{{ cmm.codeEquipmentDash }}</span>
                            <div class="container-factory">
                                <div class="factorySymbol"  [style.background-color]="cmm.factories[0].codeHexa">
                                    <span >{{ cmm.factories[0].symbol }}</span>
                                </div>
                            </div>
                        </div>
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>
        <button *ngIf="isSelect" mat-icon-button type="button" (click)="clearForm()" matTooltip="Rénitialiser">
            <mat-icon>clear</mat-icon>
        </button>
    </form>
</div>