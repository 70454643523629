import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SecurityService} from 'src/app/core/services/security/security.service';
import { RoutingService } from 'src/app/core/services/routing/routing.service';

@Injectable()
export class AdminGuard implements CanActivate
{
    constructor(private securityService: SecurityService, private routingService: RoutingService, private snackBar: MatSnackBar) { }

    canActivate()
    {
        if (this.securityService.isAdmin())
        {
            return true;
        }
        else
        {
            this.snackBar.open('Vous n\'êtes pas autorisé à accéder à la ressource demandée.', 'Fermer', {duration: 3000});
            this.routingService.goToBack();
            return false;
        }
    }
}