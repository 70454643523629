import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SecurityService} from 'src/app/core/services/security/security.service';
import { RoutingService } from 'src/app/core/services/routing/routing.service';

@Injectable()
export class NoAuthGuard implements CanActivate
{
    constructor(private router: Router, private securityService: SecurityService, private routingService: RoutingService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        if (!this.securityService.getToken() || !this.securityService.tokenIsValid())
        {
            return true;
        }
        else
        {
            this.routingService.goToAuthentificateSpace();
            return false;
        }
    }
}