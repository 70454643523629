import { Component, OnInit, Output, EventEmitter } from '@angular/core';
// Service
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { UsersService } from 'src/app/core/services/users/users.service';
// Model
import { User } from 'src/app/shared/models/user';

@Component({
  selector: 'app-bks-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user: User;
  @Output() public sidenavToggle = new EventEmitter();

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  constructor(
    public routingService: RoutingService,
    private userService: UsersService
  ) {
    this.getUser();
  }

  ngOnInit() {
  }

  getUser(): void{
    this.user = this.userService.getUser().value;
  }

}
