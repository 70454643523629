// Angular
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// Angular CDK
import { MediaMatcher } from '@angular/cdk/layout';
// Service
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { UsersService } from 'src/app/core/services/users/users.service';
import { SecurityService } from 'src/app/core/services/security/security.service';
// Model
import { User } from 'src/app/shared/models/user';
// Constante
import { dataTechConst } from 'src/app/shared/static/const/data-tech-const';
import { userConst } from 'src/app/shared/static/const/user-const';
// Modal
import { LogoutComponent } from 'src/app/shared/components/modals/logout/logout.component';

/**
 * Composant app-bks-sidebar
 * Ce composant est utilisé pour afficher le menu de navigation au format mobile
 */
@Component({
  selector: 'app-bks-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  /** ATTRIBUT */

  // Variable utilisée pour récupérer les données de l'utilisateur connecté
  user: User;

  // Evenement appliqué à l'ouverture ou la fermeture du menu de navigation
  @Output() sidenavClose = new EventEmitter();

  // Gestion de la mise en page en fonction de la taille d'un écran
  mobileQuery: MediaQueryList;

  // Identifiant du role administrateur pour un compte utilisateur
  guards: number;

  //
  modalConfirmLogout: MatDialogRef<LogoutComponent>;

  /**
   * Constructeur de la classe
   *
   * @description
   * Initialise le changement d'affichage en fonction de la taille de l'écran
   * Récupère les données de l'utilisateur connecté
   *
   * @param media           Package de mise en page pour créer des interfaces réactives qui réagissant aux changements de taille d'écran.
   * @param routingService  Service gérant la navigation de l'utilisateur dans les différents pages de la solution
   * @param userService     Service gérant l'ensemble des fonctionnalités liées aux comptes utilisateurs
   * @param dialog          Accès aux fonctionnalités liés à la gestion des boites de dialogue de la librairie Material-Angular
   * @param securityService Service gérant la sécurité de l'application
   */
  constructor(
    private media: MediaMatcher,
    public routingService: RoutingService,
    private dialog: MatDialog,
    private userService: UsersService,
    private securityService: SecurityService
  ){
    // Définition de point d'affichage du menu mobile
    this.mobileQuery = media.matchMedia(dataTechConst.breakpoint.mobile);
    // Récupération des informations liées à l'utilisateur
    this.getUser();
    // Récupération de l'identifiant associé au role administrateur d'un compte utilisateur.
    // Cette donnée est utilisée pour afficher les fonctionnalités d'administration à l'utilisateur dans le menu de navigation
    this.guards = userConst.role.admin;
  }

  /**
   * Procédure onSidenavClose
   *
   * @description
   * Engendre un évenement lorsque le menu de navigation mobile s'ouvre ou se ferme
   */
  public onSidenavClose = () => {
    if (this.mobileQuery.matches) {
      this.sidenavClose.emit();
    }
  }

  /**
   * Procédure ngOnInit
   *
   * @description
   * Initialisation du composant
   */
  ngOnInit()
  {}

  /**
   * Procédure getUser
   *
   * @description
   * Cette procédure permet de récupérer les informations de l'utilisateur connecté
   */
  getUser(): void{
    this.user = this.userService.getUser().value;
  }

  /**
   * Procédure ngOnDestroy
   *
   * @description
   * Cette procédure vise à détruire l'ensemble des observateurs du composant
   */
  ngOnDestroy() {
    this.sidenavClose = null;
  }

  /**
   * Procédure logout
   *
   * @description
   * Déconnexion d'un compte utilisateur.
   * Lorsque l'utilisateur demande à se déconnecter de l'application, un pop-up lui demande une confirmation
   */
  logout(){
    // Ouverture de la pop-up de confirmation de la déconnexion de l'utillisateur
    this.modalConfirmLogout = this.dialog.open(LogoutComponent);
    // Déconnexion en cas de confirmation par l'utilisateur
    this.modalConfirmLogout.afterClosed().subscribe (
      result => {
        if (result !== '' && result != null){
          this.securityService.logout();
        }
      }
    );
  }

}
