import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { debounceTime, startWith, switchMap, finalize, map } from 'rxjs/operators';
import { of } from 'rxjs';
// Service
import { FactoriesService } from 'src/app/core/services/factories/factories.service';
import { CmmsService } from 'src/app/core/services/cmms/cmms.service';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
// Model
import { Cmm } from 'src/app/shared/models/cmm';

@Component({
  selector: 'app-bks-cmm-search',
  templateUrl: './cmm-search.component.html',
  styleUrls: ['./cmm-search.component.scss']
})
export class CmmSearchComponent implements OnInit {

  searchCmmForm: FormGroup;
  isSelect: boolean;
  filteredCMM: Array<Cmm>;
  isLoading = false;
  errorMsg: string;

  constructor(
    private cmmsService: CmmsService,
    private formBuilder: FormBuilder,
    private routingService: RoutingService
  ){
    this.searchCmmForm = this.createSearchForm();
    this.filteredCMM = new Array<Cmm>();
    this.isSelect = false;
  }

  get searchCmm() { return this.searchCmmForm.get('searchCmm'); }

  createSearchForm(): FormGroup {
    return this.formBuilder.group({
      searchCmm: new FormControl(null)
    });
  }


  ngOnInit(): void {

    this.searchCmm.valueChanges
    .pipe(
      debounceTime(150),
      switchMap
      (
        value => {
          this.isLoading = true;
          this.filteredCMM = [];
          if (value === null || typeof value === 'object'){
            return of(null);
          } else {
            return this.cmmsService.findCmms(value, 10);
          }
        }
      ),
      map( data => {
        this.isLoading = false;
        if (data === null)
        {
          return [];
        } else {
          return data;
        }
      })
    )
    .subscribe(data => { this.filteredCMM = data; });
  }





  displayFn(cmm: Cmm): string {
    return cmm ? cmm.codeEquipmentDash : null;
  }

  checkValidityCmm(): boolean {
    return this.searchCmm.value && typeof this.searchCmm.value === 'object';
  }

  checkCmm() {
    if (!this.checkValidityCmm())
    {
      this.searchCmm.setValue(null);
      this.isSelect = false;
    }
  }

  selectCmm(cmm) {
    this.searchCmm.setValue(cmm);
    this.isSelect = true;
    this.routingService.goToCmmDetail(cmm.idCmm);
  }

  clearForm() {
    this.isSelect = false;
    this.searchCmm.setValue(null);
  }

}
