// Import Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { catchError} from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import 'rxjs/add/operator/map';
// Variable global
import { environment } from 'src/environments/environment';
// Model
import { User } from 'src/app/shared/models/user';
import { Page } from 'src/app/shared/models/page';
// Service
import { ToolsService } from 'src/app/core/services/tools/tools.service';
import { AdminGuard } from '../../guards/admin-guard';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private server              = environment.api.server;
  private api                 = environment.api.users.url;
  private version             = environment.api.users.version;
  private httpOptions         = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
  private httpOptionsResponse = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' as 'body'};
  private usernameSource: BehaviorSubject<User>;

  constructor(private http: HttpClient, private toolsService: ToolsService) { }

  public getAllUser(sort: string, order: string, page: number, itemsPerPage: number): Observable<Page<User>>
  {
    let url = `${this.server}${this.api}/${this.version}/getallusers`;
    return this.http.post<Page<User>>(url, {sort, order, page, itemsPerPage}, this.httpOptions);
  }

  public searchUsersSimple(search: string, sort: string, order: string, page: number, itemsPerPage: number): Observable<Page<User>>
  {
    let url = `${this.server}${this.api}/${this.version}/searchuserssimple`;
    return this.http.post<Page<User>>(url, {search, sort, order, page, itemsPerPage}, this.httpOptions);
  }

  public searchUsers
  (
    firstname: string,
    lastname: string,
    login: string,
    email: string,
    idRole: number,
    idState: number,
    arrayFactories: Array<number>,
    idGender: number,
    sort: string,
    order: string,
    page: number,
    itemsPerPage: number
    ): Observable<Page<User>>
  {
    let url = `${this.server}${this.api}/${this.version}/searchusers`;
    return this.http.post<Page<User>>(url, {firstname, lastname, login, email, idRole, idState, arrayFactories, idGender, sort, order, page, itemsPerPage}, this.httpOptions);
  }

  public addUser(
    idGender: number,
    lastname: string,
    firstname: string,
    email: string,
    login: string,
    pwdCryp: string,
    idRole: number,
    idState: number,
    defaultLanguage: string,
    temporyPassword: number
  ): Observable<HttpResponse<User>>
  {
    let password: string = CryptoJS.SHA512(pwdCryp).toString();
    let url = `${this.server}${this.api}/${this.version}/addUser`;
    return this.http.post<HttpResponse<User>>(url, {idGender, lastname, firstname, email, login, password, idRole, idState, defaultLanguage, temporyPassword}, this.httpOptionsResponse);
  }

  public addUserFactories(idUser: number, arrayFactories: Array<number>): Observable<boolean>
  {
    let url = `${this.server}${this.api}/${this.version}/adduserfactories`;
    return this.http.post<boolean>(url, {idUser, arrayFactories}, this.httpOptions);
  }

  public updateUserFactories(idUser: number, arrayFactories: Array<number>): Observable<boolean>
  {
    let url = `${this.server}${this.api}/${this.version}/updateuserfactories`;
    return this.http.post<boolean>(url, {idUser, arrayFactories}, this.httpOptions);
  }

  public addUserManagers(idUser: number, arrayManagers: Array<number>): Observable<boolean>
  {
    let url = `${this.server}${this.api}/${this.version}/addusermanagers`;
    return this.http.post<boolean>(url, {idUser, arrayManagers}, this.httpOptions);
  }

  public updateUserManagers(idUser: number, arrayManagers: Array<number>): Observable<boolean>
  {
    let url = `${this.server}${this.api}/${this.version}/updateusermanagers`;
    return this.http.post<boolean>(url, {idUser, arrayManagers}, this.httpOptions);
  }

  public getUserByToken(): Observable<User>
  {
    let url = `${this.server}${this.api}/${this.version}/getuserbytoken`;
    return this.http.get<User>(url, this.httpOptions).pipe(catchError(this.toolsService.errorManager<User>('getUserByToken', null)));
  }

  public saveUser(user: User): void
  {
    this.usernameSource = new BehaviorSubject<User>(user);
  }

  public getUser()
  {
    return this.usernameSource;
  }

  public getUserDetail(idUser: number): Observable<HttpResponse<User>>
  {
    let url = `${this.server}${this.api}/${this.version}/getuserdetail`;
    return this.http.post<HttpResponse<User>>(url, {idUser}, this.httpOptionsResponse);
  }

  public sendLinkForgotPassword(login: string): Observable<HttpResponse<boolean>>
  {
    let url = `${this.server}${this.api}/${this.version}/sendlinkforgotpassword`;
    return this.http.post<HttpResponse<boolean>>(url, {login}, this.httpOptionsResponse);
  }

  public updateForgotPassword(newPassword: string, tokenResetPassword: string): Observable<boolean>
  {
    let url = `${this.server}${this.api}/${this.version}/updateforgotpassword`;
    return this.http.post<boolean>(url, {newPassword, tokenResetPassword}, this.httpOptions);
  }

  public updateUser(
    idUser: number,
    idGender: number,
    lastname: string,
    firstname: string,
    email: string,
    login: string,
    idRole: number,
    idState: number,
    defaultLanguage: string,
    temporyPassword: number,
    reason: string
  ): Observable<User>
  {
    let url = `${this.server}${this.api}/${this.version}/updateuser`;
    return this.http.post<User>
    (
      url,
      {idUser, idGender, lastname, firstname, email, login, idRole, idState, temporyPassword, defaultLanguage, reason},
      this.httpOptions
    )
    .pipe(catchError(this.toolsService.errorManager<User>('updateUser', null)));
  }

  public updateUserPassword(oldPassword: string, newPassword: string): Observable<HttpResponse<boolean>>
  {
    let url = `${this.server}${this.api}/${this.version}/updateuserpassword`;
    return this.http.post<HttpResponse<boolean>>(url, {oldPassword,newPassword}, this.httpOptionsResponse);
  }

  public resetuserpassword(idUser,password): Observable<HttpResponse<boolean>>
  {
    let passwordEnc: string = CryptoJS.SHA512(password).toString();
    let url = `${this.server}${this.api}/${this.version}/resetuserpassword`;
    return this.http.post<HttpResponse<boolean>>(url, {idUser: idUser, password: passwordEnc}, this.httpOptionsResponse);
  }

  public updateUserState(idUser: number, idState: number, reason: string): Observable<boolean>
  {
    let url = `${this.server}${this.api}/${this.version}/updateuserstate`;
    return this.http.post<boolean>(url, {idUser, idState, reason}, this.httpOptions);
  }

  public checkUserByLogin(login: string, idUser: number): Observable<boolean>
  {
    let url = `${this.server}${this.api}/${this.version}/checkuserbylogin`;
    return this.http.post<boolean>(url, {login, idUser}, this.httpOptions).map(res => res)
    .pipe(catchError(this.toolsService.errorManager<boolean>('checkuserbylogin', null)));
  }

  public checkUserByEmail(email: string, idUser: number): Observable<boolean>
  {
    let url = `${this.server}${this.api}/${this.version}/checkuserbyemail`;
    return this.http.post<boolean>(url, {email, idUser}, this.httpOptions).map(res => res)
    .pipe(catchError(this.toolsService.errorManager<boolean>('searchUserByEmail', null)));
  }

  /*
  0 : All User
  1 : Admin
  2 : Operators
  */
  public searchUsersAdmin(arrayIdAdmin: number[], limit: number, search: string, flagRole: number): Observable<User[]>
  {
    let url = `${this.server}${this.api}/${this.version}/searchusersadmin`;
    return this.http.post<User[]>(url, {arrayIdAdmin, limit, search, flagRole}, this.httpOptions)
    .pipe(catchError(this.toolsService.errorManager<User[]>('searchUserByEmail', null)));
  }

}