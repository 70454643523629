<div class="container">
    <perfect-scrollbar class="container-scrollbar">
        <ul>
            <li>
                <a (click)="routing.goToManagerUsers()">
                    <i class="fas fa-users"></i>
                    <span>Comptes utilisateurs</span>
                </a>
                <a (click)="routing.goToCmmManager()">
                    <i class="fas fa-book"></i>
                    <span>CMM</span>
                </a>
                <a (click)="routing.goToReasonManager()">
                    <i class="fas fa-tag"></i>
                    <span>Motifs</span>
                </a>
                <a (click)="routing.goToOperationManager()">
                    <mat-icon>build</mat-icon>
                    <span>Operation</span>
                </a>
                <a (click)="routing.goToWarehouseManager()">
                    <mat-icon>store</mat-icon>
                    <span>Magasin</span>
                </a>
                <a (click)="routing.goToFactoryManager()">
                    <i class="fas fa-industry"></i>
                    <span>Usine</span>
                </a>
                <a (click)="routing.goToRepairCardManager()">
                    <mat-icon>description</mat-icon>
                    <span>Modèle devis</span>
                </a>
                <a (click)="routing.goToOrderManager()">
                    <mat-icon *ngIf="orderUpdated && (orderUpdated > 0) else noData" [matBadge]="orderUpdated" matBadgeColor="accent" >assignment_returned</mat-icon>
                    <ng-template #noData>
                        <mat-icon>assignment_returned</mat-icon>
                    </ng-template>
                    <span>Devis</span>

                </a>
            </li>
        </ul>
    </perfect-scrollbar>
</div>
