export const environment =
{
    version: require('../../package.json').version,
    production : false,
    domain : 'mro.collins.telino.net',
    urlMedia : 'https://mro.collins.telino.net/data/',
    urlSocket: 'https://mro.collins.telino.net:80',
    token :
    {
        timeToLive : 120000
    },
    const :
    {
        menuMobileDisplay : '(max-width: 1230px)',
    },
    language :
    {
        list : ['en', 'fr'],
        default : 'en'
    },
    api :
    {
        server : 'https://mro.collins.telino.net/NODE/',
        factory :
        {
            url     : 'api-factory',
            version : '0.0.4'
        },
        listing :
        {
            url     : 'api-listing',
            version : '0.0.4'
        },
        managefile :
        {
            url     : 'api-managefile',
            version : '0.0.4'
        },
        security :
        {
            url     : 'api-security',
            version : '0.0.4'
        },
        sonde :
        {
            url     : 'api-sondes',
            version : '0.0.4'
        },
        users :
        {
            url     : 'api-users',
            version : '0.0.4'
        },
        operation :
        {
            url     : 'api-operation',
            version : '0.0.4'
        },
        reason :
        {
            url     : 'api-reason',
            version : '0.0.4'
        },
        cmm :
        {
            url     : 'api-cmm',
            version : '0.0.4'
        },
        nomenclature :
        {
            url     : 'api-nomenclature',
            version : '0.0.4'
        },
        warehouse :
        {
            url     : 'api-warehouse',
            version : '0.0.4'
        },
        figure :
        {
            url     : 'api-figure',
            version : '0.0.4'
        },
        kit :
        {
            url     : 'api-kit',
            version : '0.0.4'
        },
        order :
        {
            url     : 'api-order',
            version : '0.0.4'
        },
        amendment :
        {
            url     : 'api-amendment',
            version : '0.0.4'
        },
        service_bulletin :
        {
            url     : 'api-sb',
            version : '0.0.4'
        },
        hotspot :
        {
            url     : 'api-hotspot',
            version : '0.0.4'
        }
    }
};
