export const regex =
{
    login : '^[a-zA-Z-]+[0-9]{0,}[\.]{1,1}[a-zA-Z-]+[0-9]{0,}$',
    password : '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$',
    email : '^[a-zA-Z0-9\.\_-]{1,}@[a-zA-Z0-9\.\_-]{1,}[a-zA-Z]{2,}$',
    OperationCode: '^[0-9]{1,4}$',
    cmmCode: '^[0-9]{1,2}-{1}[0-9]{1,2}-{1}[0-9]{1,2}$',
    //cmmCodeEquipment: '^[0-9]{1,5}-{1}[0-9]{1,4}$',
    cmmRevision : '^[0-9]{1,4}$',
    nomenclatureItem : '^[0-9]{1,}[a-zA-Z]{1,}$',
    nomenclatureLevel: '^[.]{0,}$',
    nomenclatureEffectCode: '^[0-9]{1,}[a-zA-Z]{1,}$',
    nomenclatureEffectCodeNum: '^[0-9]*$',
    nomenclatureEffectCodeString: '^[a-zA-Z]*$',
    figuleFileName: '^[a-zA-Z0-9\. \_&@àâäéèêëïîôöùûüÿç()*^§€#}=°+]*-{0,1}[0-9]{1}.{1}[0-9]{1}[\.]{1}[a-zA-Z0-9]*$',
    figureNum: '^[1-9]{1}[0-9]*$',
    figureDrawing: '^[1-9]{1}[0-9]*$',
    figItem: '^[1-9]{1}[0-9]{0,}[-]{1}[0-9]{1,}[a-zA-Z]{1,}$',
};

