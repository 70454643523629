<div class="container-form">
    <mat-card-title>
        Déconnexion ?
    </mat-card-title>
    <mat-card-content>
        <p>Vous allez être déconnecté. Les éléments non sauvegardés seront supprimés.</p>
    </mat-card-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close color="primary">Annuler</button>
        <button mat-button color="primary" (click)="valid()">Valider</button>
    </mat-dialog-actions>
</div>