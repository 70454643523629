export const nomenclatureConst = {
    import: {
        file: {
            extensionAccept : ['xls', 'xlsx'],
            sizeAccept : 1000000,
            check : {
                invalid : 0,
                valid : 1
            }
        },
        result : {
            success : 1,
            warning : 2,
            error : 3
        },
        sampleFile: {
            fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            fileName: 'Fichier-import-nomenclature-v1.0.xlsx'
        }
    },
    export: {
        fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        fileName: '-export.xlsx'
    },
    nomenclatureItem: {
        actif : 1,
        inactif: 2,
        type: {
            isNomenclatureItem: {
                valNum: 0,
                valString: 'item'
            },
            isDash: {
                valNum: 1,
                valString: 'dash'
            }
        },
        isDashFictif: {
            valNumYes: 1,
            valNumNo: 0,
            valString: 'dashFictif'
        },
        numFigureForDash: 1,
        isQuoteable: {
            no: 0,
            yes: 1,
            symbol : 'NP'
        },
        effectCode: {
            separatorTech: '-',
            separatorDisplay: ''
        },
        illustrated: {
            no : 0,
            yes : 1
        },
        isKitItem : {
            no: 0,
            yes: 1
        },
        media : {
            delete: {
                yes : 1,
                no: 0
            }
        }
    },
    actionBasket: {
        add: 0,
        update: 1
    },
    label: {
        figItemParent: "Fig-Item Parent"
    },
    search: {
        withDash: 1,
        noDash:0
    }
};
