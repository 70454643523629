import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
// Model
import { Nomenclature } from 'src/app/shared/models/nomenclature';
// Constante
import { nomenclatureConst } from 'src/app/shared/static/const/nomenclature-const';
import { userConst } from 'src/app/shared/static/const/user-const';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    private router: Router,
    private location: Location
  ) { }

  getParam(activatedRoute: ActivatedRoute, param: string): string {
    return activatedRoute.snapshot.paramMap.get(param);
  }
  getParamSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot, param: string): string {
    return activatedRouteSnapshot.paramMap.get(param);
  }

  getParamIdFigure(activatedRoute: ActivatedRoute): number {
    return +this.getParam(activatedRoute, 'idFigure');
  }
  getParamIdCmm(activatedRoute: ActivatedRoute): number {
    return +this.getParam(activatedRoute, 'id');
  }
  getParamIdCmmByRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return +this.getParamSnapshot(activatedRouteSnapshot, 'id');
  }
  getParamIdNomenclatureItem(activatedRoute: ActivatedRoute): number {
    return +this.getParam(activatedRoute, 'idNomenclatureItem');
  }
  getParamTypeNomenclatureItem(activatedRoute: ActivatedRoute): string {
    return this.getParam(activatedRoute, 'type');
  }
  getParamIdKit(activatedRoute: ActivatedRoute): number {
    return +this.getParam(activatedRoute, 'idKit');
  }
  getParamIdOrder(activatedRoute: ActivatedRoute): number {
    return +this.getParam(activatedRoute, 'idOrder');
  }
  getParamIdWarehouse(activatedRoute: ActivatedRoute): number {
    return +this.getParam(activatedRoute, 'idWarehouse');
  }
  getParamIdWarehouseByRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return +this.getParamSnapshot(activatedRouteSnapshot, 'idWarehouse');
  }
  getParamIdAmendmentByRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return +this.getParamSnapshot(activatedRouteSnapshot, 'idAmendment');
  }
  getParamIdUserByRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return +this.getParamSnapshot(activatedRouteSnapshot, 'idUser');
  }
  getParamIdOperationByRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return +this.getParamSnapshot(activatedRouteSnapshot, 'idOperation');
  }
  getParamIdReasonByRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return +this.getParamSnapshot(activatedRouteSnapshot, 'idReason');
  }
  getParamIdFactoryByRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return +this.getParamSnapshot(activatedRouteSnapshot, 'idFactory');
  }
  getParamIdDrawingByRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return +this.getParamSnapshot(activatedRouteSnapshot, 'idDrawing');
  }
  getParamIdSbByRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return +this.getParamSnapshot(activatedRouteSnapshot, 'idSb');
  }
  getParamIdOrderByRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return +this.getParamSnapshot(activatedRouteSnapshot, 'idOrder');
  }
  getParamIdKitByRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return +this.getParamSnapshot(activatedRouteSnapshot, 'idKit');
  }
  getParamIdNomenclatureByRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): number {
    return +this.getParamSnapshot(activatedRouteSnapshot, 'idNomenclatureItem');
  }
  getParamTypeNomenclatureByRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot): string {
    return this.getParamSnapshot(activatedRouteSnapshot, 'type');
  }

  goToBack(): void { this.location.back(); }
  goToReload(): void { this.router.navigate(['']); }

  goToAuthentificateSpace(): void { this.router.navigate(['/']); }

  goToDashboard(idUserRole: number): void {
    idUserRole === userConst.role.admin ? this.goToAdministrationSpace() : this.goToOperationSpace();
  }

  goToLogin(): void { this.router.navigate(['/login']); }
  goToAdministrationSpace(): void { this.router.navigate(['/administration']); }
  goToOperationSpace(): void { this.router.navigate(['/']); }
  goToChangeTemporyPassword(): void { this.router.navigate(['/change-tempory-password']); }
  goToMyAccount(): void { this.router.navigate(['/my-account']); }

  // Administration
  goToManagerUsers(): void { this.router.navigate(['/administration', 'user']); }

  // Administration user
  goToUserCreate(): void { this.router.navigate(['/administration', 'user', 'create']); }
  goToUserDetail(idUser: number): void { this.router.navigate(['/administration', 'user', idUser, 'detail']); }
  goToUserUpdate(idUser: number): void { this.router.navigate(['/administration', 'user', idUser, 'update']); }

  // Administration Operation
  goToOperationManager(): void { this.router.navigate(['/administration', 'operation']); }
  goToOperationCreate(): void { this.router.navigate(['/administration', 'operation', 'create']); }
  goToOperationDetail(idOperation: number): void { this.router.navigate(['/administration', 'operation', idOperation, 'detail']); }
  goToOperationUpdate(idOperation: number): void { this.router.navigate(['/administration', 'operation', idOperation, 'update']); }

  // Administration Reason
  goToReasonManager(): void { this.router.navigate(['/administration', 'reason']); }
  goToReasonCreate(): void { this.router.navigate(['/administration', 'reason', 'create']); }
  goToReasonDetail(idReason: number): void { this.router.navigate(['/administration', 'reason', idReason, 'detail']); }
  goToReasonUpdate(idReason: number): void { this.router.navigate(['/administration', 'reason', idReason, 'update']); }

  // Administration Usine
  goToFactoryManager(): void { this.router.navigate(['/administration', 'factory']); }
  goToFactoryDetail(idFactory: number): void { this.router.navigate(['/administration', 'factory', idFactory, 'detail']); }
  goToFactoryUpdate(idFactory: number): void { this.router.navigate(['/administration', 'factory', idFactory, 'update']); }

  // Administration CMM
  goToCmmManager(): void { this.router.navigate(['/administration', 'cmm']); }
  goToCmmCreate(): void { this.router.navigate(['/administration', 'cmm', 'create']); }
  goToCmmDetail(idCmm: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'detail']); }
  goToCmmUpdate(idCmm: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'update']); }
  goToAmendmentList(idCmm: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'amendment']); }

  // Figure
  goToCmmFigureCreate(idCmm: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'figure', 'add']); }
  goToCmmFigureDetail(idCmm: number, idFigure: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'figure', idFigure, 'detail']); }
  goToCmmFigureUpdate(idCmm: number, idFigure: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'figure', idFigure, 'update']); }
  goToCmmFigureHotspotEditor(idCmm: number, idFigure: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'figure', idFigure, 'hotspot']); }
  
  // Administration Nomenclature
  goToNomenclatureAdd(idCmm: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'nomenclature', 'add']); }
  goToNomenclatureUpdate(idCmm: number, nomenclature: Nomenclature): void 
  {
    let type: string;
    if (nomenclature.idDash) {
      type =  nomenclatureConst.nomenclatureItem.type.isDash.valString;
      this.router.navigate(['/administration', 'cmm', idCmm, 'nomenclature', nomenclature.idDash, 'type', type, 'update']); 
    } else {
      type =  nomenclatureConst.nomenclatureItem.type.isNomenclatureItem.valString;
      this.router.navigate(['/administration', 'cmm', idCmm, 'nomenclature', nomenclature.idNomenclatureItem, 'type', type, 'update']);
    }   
  }
  goToNomenclatureImport(idCmm: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'nomenclature', 'import']); }
  goToNomenclatureDetail(idCmm: number, idNomenclature: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'nomenclature', idNomenclature, 'type', nomenclatureConst.nomenclatureItem.type.isNomenclatureItem.valString]); }
  goToDashDetail(idCmm: number, idDash: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'nomenclature', idDash, 'type', nomenclatureConst.nomenclatureItem.type.isDash.valString]); }
  goToDetailNomenclature(idCmm: number, nomenclature: Nomenclature)
  {
    let isDash: boolean;
    isDash = nomenclature.idDash ? true : false;
    if (isDash){
      this.goToDashDetail(
        idCmm,
        nomenclature.idDash
      );
    } else {
      this.goToNomenclatureDetail(
        idCmm,
        nomenclature.idNomenclatureItem
      );
    }
  }

  // Administration Magasin
  goToWarehouseManager(): void { this.router.navigate(['/administration', 'warehouse']); }
  goToWarehouseCreate(): void { this.router.navigate(['/administration', 'warehouse', 'create']); }
  goToWarehouseDetail(idWarehouse: number): void { this.router.navigate(['/administration', 'warehouse', idWarehouse, 'detail']); }
  goToWarehouseUpdate(idWarehouse: number): void { this.router.navigate(['/administration', 'warehouse', idWarehouse, 'update']); }

  // Administration kit
  goToKitCreate(idCmm: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'kit', 'create']); }
  goToKitDetail(idCmm: number, idKit: number): void { this.router.navigate(['administration', 'cmm', idCmm, 'kit', idKit, 'detail']); }
  goToKitUpdate(idCmm: number, idKit: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'kit', idKit, 'update']); }

  // Administration Service Bulletin
  goToSbDetail(idCmm: number, idSb: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'sb', idSb, 'detail']); }
  goToSbUpdate(idCmm: number, idSb: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'sb', idSb, 'update']); }
  goToSbAdd(idCmm: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'sb', 'add']); }
  goToSbList(idCmm: number): void { this.router.navigate(['/administration', 'cmm', idCmm, 'sb']); }

  // Administration amendment
  goToAmendmentDetail(idCmm: number, idAmendment: number): void {
    this.router.navigate(['/administration', 'cmm', idCmm, 'amendment', idAmendment, 'detail']); }
  goToAmendmentUpdate(idCmm: number, idAmendment: number): void {
    this.router.navigate(['/administration', 'cmm', idCmm, 'amendment', idAmendment, 'update']); }
  goToAmendmentAdd(idCmm: number): void {
    this.router.navigate(['/administration', 'cmm', idCmm, 'amendment', 'add']); }

  // Administration modèle de devis
  goToRepairCardManager(): void { this.router.navigate(['/administration', 'repair-card']); }
  goToRepairCardUpdate(): void { this.router.navigate(['/administration', 'repair-card', 'update']); }

  // Administration des commandes en cours et réalisées
  goToOrderManager(): void { this.router.navigate(['/administration', 'order']); }
  goToOrderDetail(idOrder: number): void { this.router.navigate(['/administration', 'order', idOrder, 'detail']); }

  // Commande d'un opérateur
  goToQuoteManager(): void { this.router.navigate(['quote']); }
  goToQuoteDetail(idQuote: number): void { this.router.navigate(['/quote', idQuote, 'detail']); }

  // Panier
  goToBasket(): void { this.router.navigate(['basket']); }
  goToQuoteGenerator(idOrder: number): void { this.router.navigate(['/basket', idOrder, 'quote-generator']); }

  // Création d'un devis et recherche de pièce
  goToSearchPart(): void { this.router.navigate(['search-part']); }
  goToSelectPart(): void { this.router.navigate(['/part']); }
  goToSelectPartKit(idKit: number): void { this.router.navigate(['/part', 'kit', idKit]); }
  goToFigureDetail(idFigure: number): void { this.router.navigate(['/part', 'figure', idFigure]); }

  goToAdvancedSearch(): void { this.router.navigate(['/part', 'advanced-search']); }

}
