import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// Librairie
import { CookieService } from 'ngx-cookie-service';
import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { Observable } from 'rxjs';
// Service
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { OrderService } from 'src/app/core/services/orders/order.service';
// Constante
import { userConst } from 'src/app/shared/static/const/user-const';


@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  /* ATTRIBUT*/
  // Désignation du serveur du WS
  private server         = environment.api.server;
  // Désignation du WS
  private api            = environment.api.security.url;
  // Version du WS
  private version        = environment.api.security.version;
  // Nom de domaine de l'application
  private domain         = environment.domain;
  // Enveloppe de retour du WS
  private httpOptions    = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};
  // Enveloppe de retour du WS intégrant la gestion des codes HTTP
  private httpOptionsResponse = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' as 'body'};


  /**
   * Constructeur de la classe
   * @param http            Gestion des échanges de type HTTP
   * @param router          Gestion de la navigation dans l'application
   * @param cookieService   Gestion des cookies
   */
  constructor(
    private http: HttpClient,
    private router: RoutingService,
    private cookieService: CookieService,
    private orderService: OrderService
  ) { }

  /**
   * Fonction doLogin
   * Cette fonction est utilisée pour permettre l'authentification de l'utilisateur
   *
   * @param login     login de l'utilisateur (format : prenom.nom)
   * @param password  mot de passe cripté de l'utilisateur
   *
   * @returns si les informations permettent d'identifié l'utilisateur, un token est retourné
   */
  public doLogin(login: string, password: string): Observable<string>
  {
    let url = `${this.server}${this.api}/${this.version}/dologin`;
    return this.http.post<string>(url, {login, password}, this.httpOptions);
  }

  /**
   * Fonction refreshToken
   * Permet de soumettre au serveur une demande de rafraichissement de token
   *
   * @param refreshToken token de sécurité utilisé pour vérifier l'identité de l'utilisateur connecté
   * 
   * @returns renvoi un nouveau token actualisé
   */
  public refreshToken(refreshToken: string): Observable<string>
  {
    let url = `${this.server}${this.api}/${this.version}/refreshtoken`;
    return this.http.post<string>(url, {refreshToken}, this.httpOptions);
  }

  /**
   * Procédure logout
   * Cette fonction vise à déconnecter un utilisateur en supprimant les informations stockées dans le cookie du navigateur.
   * En se déconnectant, l'utilisateur est renvoyé vers la page de login.
   */
  logout(): void
  {
    this.orderService.closeSocketGetOrderUpdate();
    this.deleteToken();
    this.router.goToLogin();
  }

  /**
   * Procédure deleteToken
   * Supprime les informations de connexion de l'utilisateur stockées dans un cookie
   */
  deleteToken(): void
  {
    this.cookieService.delete('BKSMroToken', null, this.domain);
    this.cookieService.delete('BKSMroTokenRefresh', null, this.domain);
    this.cookieService.delete('BKSMroExpireAt', null, this.domain);
  }

  /**
   * Procédure setToken
   * Cette fonction est utilisée pour décoder le token de l'utilisateur.
   *
   * @Param token : Object. Prend en paramètre le token de l'utilisateur
   */
  setSession(token: any): void
  {
    const tokenDecode = jwt_decode(token.access_token);
    const userRole = tokenDecode.role; // 1-2
    const userTemp = tokenDecode.temp; // Si égale à 1 le mot de passe est temporaire
    const expiresat = tokenDecode.exp;

    // Conservation dans un cookie le token et la date d'expiration du token
    // Si l'utilisateur est un utilisateur ayant un mot de passe temporaire,
    // le token permettant de raffraichir la session n'est pas conservé en mémoire
    this.cookieService.set( 'BKSMroToken', token.access_token, expiresat, '/', this.domain);
    this.cookieService.set( 'BKSMroExpireAt', expiresat, expiresat, '/', this.domain);

    // Vérification de la pérénité du mot de passe
    if (userTemp !== userConst.temporyPassword)
    {
      // Conservation dans un cookie du token permettant le raffraichissement du mot de passe
      this.cookieService.set( 'BKSMroTokenRefresh', token.refresh_token, expiresat, '/', this.domain);

      this.orderService.initSocketGetOrderUpdate();

      // Redirection de l'utilisateur en fonction de son rôle : espace d'administration ou espace opérateur
      if (userRole === userConst.role.admin) {
        this.router.goToAdministrationSpace();
      } else {
        this.router.goToOperationSpace();
      }
    }
    else
    {
      // Si le mot de passe est temporaire, l'utilisateur est renvoyé vers la page de changement de mot de passe
      this.router.goToChangeTemporyPassword();
    }
  }

  /**
   * Procédure updateSession
   * Cette fonction permet de mettre à jour les tokens stockés dans le cookie de du navigateur de l'utilisateur
   *
   * @Param token : string. Nouveau token de l'utilisateur à utilisé pour vérifier son identité
   */
  updateSession(token: any): void
  {
    // Décodage du token
    const expiresat = jwt_decode(token.access_token);
    // Raffraichissement du cookie
    this.cookieService.set( 'BKSMroToken', token.refresh_token, expiresat.exp, '/', this.domain);
    this.cookieService.set( 'BKSMroTokenRefresh', token.refresh_token, expiresat.exp, '/', this.domain);
    this.cookieService.set( 'BKSMroExpireAt', expiresat.exp, expiresat.exp, '/', this.domain);
  }

  /**
   * Procédure getToken
   * Cette fonction permet de recupérer le token stocké dans le cookie de l'application
   *
   * @returns renvoi le token de l'utilisateur connecté
   */
  getToken(): string
  {
    return this.cookieService.get('BKSMroToken');
  }
  /**
   * Procédure getRefreshToken
   * Cette fonction permet de recupérer le token de raffraichissement stocké dans le cookie de l'application
   * 
   * @returns renvoi le token de rafraichissement de l'utilisateur connecté
   */
  getRefreshToken(): string
  {
    return this.cookieService.get('BKSMroTokenRefresh');
  }

  /**
   * Fonction tokenIsValid
   * Cette fonction est utilisée afin de vérifier la validité du token.
   * La date d'expiration du token est vérifié par rapport à la date du serveur
   *
   * @return boolean :
   * - retourne vrai si la date d'expiration est supérieure à la date du serveur
   * - retourne faux sinon
   */
  tokenIsValid()
  {
    return ( moment().unix() < this.getExpirationToken() );
  }

  /**
   * Fonction getExpirationToken
   * Cette fonction est utilisée pour récupérer la date d'expiration du token
   * 
   * @return La date d'expiration du token. Si aucun token, renvoit null.
   */
  getExpirationToken(): number
  {
    const expiration = this.cookieService.get('BKSMroExpireAt');

    if (!expiration) {
      return null;
    } else {
      const expiresAt = JSON.parse(expiration);
      return expiresAt;
    }
  }

  /**
   * Fonction isAdmin
   * Cette fonction est utilisée pour vérifier que l'utilisateur connecté est un administrateur
   * 
   * @return Vrai sur l'utilisateur est un administrateur, Faux sinon.
   */
  isAdmin(): boolean
  {
    const tokenDecode = jwt_decode(this.getToken());
    const userRole = tokenDecode.role; // 1-2

    return (userRole === userConst.role.admin);
  }

  /**
   * Fonction isTemporyPassword
   * Cette fonction est utilisée pour vérifier que l'utilisateur connecté à un mot de passe temporaire
   *
   * @return Vrai sur l'utilisateur a un mot de passe temporaire, Faux sinon.
   */
  isTemporyPassword(): boolean
  {
    const tokenDecode = jwt_decode(this.getToken());
    const userTemp = tokenDecode.temp; // Si égale à 1 le mot de passe est temporaire

    return (userTemp === userConst.temporyPassword);
  }

  isSocketAlive(): void {
    if (this.isAdmin() && !this.orderService.getOrderUpdated()) {
      this.orderService.initSocketGetOrderUpdate();
    }
  }

  /**
   * Fonction getlastversion
   * Permet de récupérer le numéro de version du backend
   * 
   * @returns renvoi le numéro de version
   */
  getLastVersion(): Observable<HttpResponse<String>> {
    let url = `${this.server}${this.api}/${this.version}/getlastversion`;
    return this.http.get<HttpResponse<String>>(url, this.httpOptionsResponse);
  }

}
