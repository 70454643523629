import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { OrderService } from 'src/app/core/services/orders/order.service';
@Component({
  selector: 'app-bks-sidebar-administration',
  templateUrl: './sidebar-administration.component.html',
  styleUrls: ['./sidebar-administration.component.scss']
})
export class SidebarAdministrationComponent implements OnInit {

  orderUpdated: number;

  constructor(
    public routing: RoutingService,
    private orderService: OrderService
  ) {
    this.orderService.changeEmitted$.subscribe(
      res => this.orderUpdated = res
    );
  }

  ngOnInit(): void {

  }

}
