<mat-toolbar class="container-header">
    <div class="container-menu">
        <button mat-icon-button (click)="onToggleSidenav()">
        <mat-icon>menu</mat-icon>
    </button>
    </div>
    <div class="container-logo">
        <a (click)="routingService.goToDashboard(user.idRole)">
            <img alt="Collins Aerospace - MRO Market" src="../../../../assets/media/logo/collins_aerospace_logo_white.png"/>
        </a>
    </div>
    <div class="triangle"></div>
    <div class="container-nav">
        <app-bks-navbar></app-bks-navbar>
    </div>
    <div class="triangle-inverse"></div>
    <div class="container-basket">    
        <a (click)="routingService.goToBasket()">
            <mat-icon>shopping_cart</mat-icon>
        </a>
    </div>
</mat-toolbar>