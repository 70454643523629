// Import Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';
// Variable global
import { environment } from 'src/environments/environment';
// Service
import { UsersService } from 'src/app/core/services/users/users.service';
import { OperationService } from 'src/app/core/services/operations/operation.service';
import { CmmsService } from 'src/app/core/services/cmms/cmms.service';
import { WarehouseService } from 'src/app/core/services/warehouses/warehouse.service';
import { KitService } from 'src/app/core/services/kits/kit.service';
// constante
import { regex } from 'src/app/shared/static/regex';
// Librairie
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { nomenclatureConst } from 'src/app/shared/static/const/nomenclature-const';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  private server = environment.api.server;
  private api = environment.api.sonde.url;
  private version = environment.api.sonde.version;
  private httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient, private snackBar: MatSnackBar) { }

  matchPassword(control: FormGroup) {
    const password = control.get('password').value;
    const passwordConfirm = control.get('passwordConfirm').value;

    if (password != null && passwordConfirm != null) {
      if (password === passwordConfirm) {
        return null;
      } else {
        return { 'matchPassword': true };
      }
    } else {
      return null;
    }
  }

    matchEffectCodeAmd(control: FormGroup) {
      const effectCodeNum = control.get('outEffectCodeNumber');
      const effectCodeString = control.get('outEffectCodeString');
      const inPn  = control.get('inPn');

  
      if (inPn.value && inPn.value.idDash){
        return null;
      }

      const effectCodeNumIsEmpty = effectCodeNum.value === null || effectCodeNum.value === '';
      const effectCodeStringIsEmpty = effectCodeString.value === null || effectCodeString.value.length === 0;
  
      if (effectCodeNumIsEmpty && effectCodeStringIsEmpty) {
        return null;
      } else if ((!effectCodeNumIsEmpty && effectCodeStringIsEmpty) || (effectCodeNumIsEmpty && !effectCodeStringIsEmpty)) {
        return { 'matchEffectCode': true };
      } else if ((!effectCodeNumIsEmpty && effectCodeNum.valid) &&  (!effectCodeStringIsEmpty && effectCodeString.valid)) {
        return null;
      } else {
        return { 'matchEffectCode': true };
      }
    }


  matchEffectCode(control: FormGroup) {
    const effectCodeNum = control.get('nomenclatureEffectCodeNumber');
    const effectCodeString = control.get('nomenclatureEffectCodeString');

    const effectCodeNumIsEmpty = effectCodeNum.value === null || effectCodeNum.value === '';
    const effectCodeStringIsEmpty = effectCodeString.value === null || effectCodeString.value.length === 0;

    if (effectCodeNumIsEmpty && effectCodeStringIsEmpty) {
      return null;
    } else if ((!effectCodeNumIsEmpty && effectCodeStringIsEmpty) || (effectCodeNumIsEmpty && !effectCodeStringIsEmpty)) {
      return { 'matchEffectCode': true };
    } else if ((!effectCodeNumIsEmpty && effectCodeNum.valid) &&  (!effectCodeStringIsEmpty && effectCodeString.valid)) {
      return null;
    } else {
      return { 'matchEffectCode': true };
    }
  }

  checkFormatEffectCodeString(arrayStr: Array<String>): boolean{
    if (arrayStr) {
      let valRet: boolean = false;
      let cpt: number = 0;
      for (cpt; cpt < arrayStr.length; cpt++){
        if (!arrayStr[cpt].match(regex.nomenclatureEffectCodeString)) {
          valRet = true;
          break;
        }
      }
      return valRet;
    } else {
      return false;
    }
    
  }

  // 400 et 424
  testErreur(code: number) {
    let url = `${this.server}${this.api}/${this.version}/testerreur`;
    return this.http.post(url, { code }, this.httpOptions).pipe(catchError(this.errorManager<any>('testErreur', null)));
  }

  // Todo : A enrichir dans un second temps
  public errorManager<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error.status === 401) {
        this.snackBar.open('Vous n\êtes pas autorisé à réaliser cette action', 'Fermer', { duration: 3000 });
      } else if (error.status === 409) {
        this.snackBar.open('Session inactive. Merci de raffraichir la page.', 'Fermer', { duration: 5000 });
      } else if (error.status === 424) {
        this.snackBar.open('Erreur interne. Veuillez contacter votre administrateur.', 'Fermer', { duration: 3000 });
      } else if (error.status !== 409 && error.status !== 204) {
        this.snackBar.open('Une erreur est survenue. Merci de réessayer ultérieurement', 'Fermer', { duration: 3000 });
      }
      return of(result as T);
    };
  }

  public dataURItoBlob(dataURI: any, fileType: string) {
    try {
      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: fileType });
      return blob;
    } catch (error) {
      this.snackBar.open('Une erreur est survenue. Merci de réessayer ultérieurement', 'Fermer', { duration: 3000 });
    }
  }

  checkEmailValidator(usersService: UsersService, idUser: number) {
    return (control: AbstractControl) => {
      return usersService.checkUserByEmail(control.value, idUser).map(res => {
        return !res ? { checkEmail: true } : null;
      });
    };
  }

  checkLoginValidator(usersService: UsersService, idUser: number) {
    return (control: AbstractControl) => {
      return usersService.checkUserByLogin(control.value, idUser).map(res => {
        return !res ? { checkLogin: true } : null;
      });
    };
  }

  resetFormControl(absCtrl: AbstractControl, disable: boolean) {
    if (disable) { absCtrl.disable(); }
    absCtrl.setValue(null);
    absCtrl.markAsUntouched();
  }

  checkCodeCmmFactory(cmmService: CmmsService, idFactory: number, idCmm: number) {
    return (control: AbstractControl) => {
      return cmmService.checkCodeCmmFactory(idFactory, control.value, idCmm).map(res => {
        return !res ? { checkCodeCmmFactory: true } : null;
      });
    };
  }

  checkCodeEquipmentFactory(cmmService: CmmsService, idFactory: number, idCmm: number) {
    return (control: AbstractControl) => {
      return cmmService.checkCodeEquipmentFactory(idFactory, control.value, idCmm).map(res => {
        return !res ? { checkCodeEquipmentFactory: true } : null;
      });
    };
  }

  checkCmmOperationCode(operationService: OperationService, idCmm: number, idOperation: number) {
    return (control: AbstractControl) => {
      return operationService.checkCmmOperationCode(idCmm, control.value, idOperation).map(res => {
        return !res ? { checkCmmOperationCode: true } : null;
      });
    };
  }

  checkCodeWarehouse(warehouseService: WarehouseService, idFactory: number, idWarehouse: number) {
    return (control: AbstractControl) => {
      return warehouseService.checkCodeWarehouse(control.value, idFactory, idWarehouse).map(res => {
        return !res ? { checkCodeWarehouse: true } : null;
      });
    };
  }

  checkKitName(kitService: KitService, idCmm: number, idKit: number) {
    return (control: AbstractControl) => {
      return kitService.checkKitName(control.value, idCmm, idKit).map(res => {
        return !res ? { checkKitName: true } : null;
      });
    };
  }


  checkDateFormatStatPeriodValidator(control: FormGroup) {
    const dateStartPeriodOne = control.get('dateStartPeriodOne').value;
    const dateEndPeriodOne = control.get('dateEndPeriodOne').value;
    const dateStartPeriodTwo = control.get('dateStartPeriodTwo').value;
    const dateEndPeriodTwo = control.get('dateEndPeriodTwo').value;

    if (dateStartPeriodOne && dateEndPeriodOne && dateStartPeriodTwo && dateEndPeriodTwo) {
      return (
        dateStartPeriodOne.getTime() <= dateEndPeriodOne.getTime()
        && dateEndPeriodTwo.getTime() <= dateStartPeriodOne.getTime()
        && dateStartPeriodTwo.getTime() <= dateEndPeriodTwo.getTime()
      ) ? null : { 'checkDateFormatValidatorStatPeriod': true };
    } else {
      return null;
    }
  };


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: nomenclatureConst.export.fileType});
     FileSaver.saveAs(data, fileName + nomenclatureConst.export.fileName);
  }

}
