export const quoteConst = {
    formInformationQuote: {
        lblPnIn : {
            label: 'P/N In',
            msgError: 'Veuillez renseigner un P/N In'
        },
        lblPnOut : {
            label: 'P/N Out',
            msgError: 'Veuillez renseigner un P/N Out'
        }
    },
    state: {
        inProgress: {
            id: 3,
            label: 'En cours'
        },
        ending: {
            id: 4,
            label: 'Terminé'
        }
    },
    typeQuote: {
        new: 1,
        additive: 2
    },
    quoteType: {
        new: {
            id: 1,
            label: 'Nouveau'
        },
        additive: {
            id: 2,
            label: 'Additif'
        },
    },
    eventUpdateQuote: 'orders_management',
    latestQuoteConfiguration: {
        sort: 'dateCreate',
        asc: 'desc',
        page: 0,
        itemPerPage: 8,
        searchItem: null
    },
    filterDistinctDash: {
        yes: 1,
        no: 0
    }
};
