import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SecurityService } from 'src/app/core/services/security/security.service';


@Injectable()
export class SecurityInterceptor implements HttpInterceptor
{
  constructor(private securityService: SecurityService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    let token = this.securityService.getToken();

    if (token)
    {
      const cloned = req.clone
      (
        {
          headers: req.headers.append("Authorization","Bearer " + token)
        }
      )
      return next.handle(cloned).pipe
      (
        catchError((error: HttpErrorResponse) => 
        {
          if (error.status === 403)
          {
            this.securityService.logout();
          }
          else if (error.status === 409)
          {
            let refeshToken: string;
            refeshToken = this.securityService.getRefreshToken();
            this.securityService.refreshToken(refeshToken).subscribe
            (
              data =>
              {
                this.securityService.updateSession(data);
              },
              erreur => { }
            );
          }
          return throwError(error);
        }
      ));
    }
    else
    {
      return next.handle(req);
    }
  }
}
