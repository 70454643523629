import { Component } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectorRef } from '@angular/core';
// Service
import { SecurityService } from 'src/app/core/services/security/security.service';
import { RoutingService } from 'src/app/core/services/routing/routing.service';
import { UsersService } from 'src/app/core/services/users/users.service';
// Model
import { User } from 'src/app/shared/models/user';
// Constante
import { environment } from 'src/environments/environment';
import { userConst } from 'src/app/shared/static/const/user-const';
// component
import { LogoutComponent } from 'src/app/shared/components/modals/logout/logout.component';


@Component({
  selector: 'app-bks-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  user: User;
  guards: number;
  idAdmin : number = userConst.role.admin;
  mobileQuery: MediaQueryList;
  modalConfirmLogout: MatDialogRef<LogoutComponent>;

  private _mobileQueryListener: () => void;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private securityService: SecurityService,
    private dialog: MatDialog,
    private userService: UsersService,
    public routingService: RoutingService
  )
  {
    this.mobileQuery = media.matchMedia(environment.const.menuMobileDisplay);
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.guards = userConst.role.admin;
    this.user = this.userService.getUser().value;
  }


  logout()
  {
    this.modalConfirmLogout = this.dialog.open(LogoutComponent);
    this.modalConfirmLogout.afterClosed().subscribe
    (
      result =>
      {
        if (result !== '' && result != null)
        {
          this.securityService.logout();
        }
      }
    );
  }
}



