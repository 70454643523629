import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SecurityService} from 'src/app/core/services/security/security.service';
import { RoutingService } from 'src/app/core/services/routing/routing.service';

@Injectable()
export class AuthGuard implements CanActivate
{
    constructor(private router: Router, private securityService: SecurityService, private routingService: RoutingService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        if (this.securityService.getToken() && this.securityService.tokenIsValid())
        {
            if ( this.securityService.isTemporyPassword())
            {
                this.routingService.goToChangeTemporyPassword();
                return false;
            } else {
                this.securityService.isSocketAlive();
                return true;
            }
        }
        else
        {
            this.routingService.goToLogin();
            return false;
        }
    }
}