
<mat-sidenav-container *ngIf="userConnected" class="container-header">
  <mat-sidenav #sidenav [opened]="mobileQuery.matches ? true : false" role="navigation" [mode]="mobileQuery.matches ? 'over' : 'side'"
                  [fixedInViewport]="mobileQuery.matches" fixedTopGap="0">
    <app-bks-sidebar mode="side" (sidenavClose)="sidenav.close()"></app-bks-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-bks-header (sidenavToggle)="sidenav.toggle()"></app-bks-header>
    <div class="container-main">
        <section *ngIf='mobileQuery.matches ? false : true && userConnected.idRole === guards'>
            <app-bks-sidebar-administration></app-bks-sidebar-administration>
            <div class="container-version">
              <span [matTooltip]="toolTipVersion">{{ version }}</span>
            </div>
        </section>
        <main>
            <router-outlet></router-outlet>
        </main>
    </div>
      
  </mat-sidenav-content>
</mat-sidenav-container>