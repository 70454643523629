export const userConst = {
    role : {
      admin : 1,
      operator: 2
    },
    gender: {
        man : 1,
        woman : 2
    },
    state: {
        actif : 1,
        inactif : 2
    },
    temporyPassword : 1,
    maxManager : 3,
    searchAdminAutocompleteLimit : 10,
    searchUser : {
        all: 0,
        admin : 1,
        operator: 2
    }
};
