<perfect-scrollbar class="container-scrollbar">
  <div id="sidebar">
      <div class="filligramme">
        <div class="container-logo">
          <img 
            alt="Collins Aerospace - MRO Market" 
            src="../../../../assets/media/logo/collins_aerospace_logo_white.png"
          >
        </div>
        
        <mat-nav-list>
          <mat-list-item>
            <a matLine (click)="routingService.goToDashboard(user.idRole); onSidenavClose()">
              <mat-icon>home</mat-icon>
              <span class="nav-caption">Accueil</span>
            </a>
          </mat-list-item>

          <mat-list-item>
            <a matLine (click)="routingService.goToMyAccount(); onSidenavClose()">
                <mat-icon>account_circle</mat-icon>
                <span>Mon compte</span>
            </a>
          </mat-list-item>

          <mat-list-item>
            <a matLine (click)="routingService.goToQuoteManager(); onSidenavClose()">
              <mat-icon>assignment_returned</mat-icon>
              <span>Mes devis</span>
            </a>
          </mat-list-item>

          <mat-list-item>
            <a matLine (click)="routingService.goToOperationSpace(); onSidenavClose()">
              <mat-icon>search</mat-icon>
              <span>Recherche pièce</span>
            </a>
          </mat-list-item>

          <mat-list-item>
            <a matLine (click)="logout()">
              <mat-icon>power_settings_new</mat-icon>
              <span>Se déconnecter</span>
            </a>
          </mat-list-item>

          <div *ngIf="user.idRole === guards" mat-subheader class="title-divider">
            <span>Administration</span>
          </div>
          
          <mat-list-item *ngIf="user.idRole === guards">
            <a matLine (click)="routingService.goToManagerUsers(); onSidenavClose()">
              <i class="fas fa-users inline"></i>
              <span>Comptes utilisateurs</span>
            </a>
          </mat-list-item>

          <mat-list-item *ngIf="user.idRole === guards">
            <a matLine (click)="routingService.goToCmmManager(); onSidenavClose()">
              <i class="fas fa-book"></i>
              <span>CMM</span>
            </a>
          </mat-list-item>

          <mat-list-item *ngIf="user.idRole === guards">
            <a matLine (click)="routingService.goToOrderManager(); onSidenavClose()">
              <mat-icon>assignment_returned</mat-icon>
              <span>Devis</span>
            </a>
          </mat-list-item>

          <mat-list-item *ngIf="user.idRole === guards">
            <a matLine (click)="routingService.goToReasonManager(); onSidenavClose()">
              <i class="fas fa-tag"></i>
              <span>Motifs</span>
            </a>
          </mat-list-item>

          <mat-list-item *ngIf="user.idRole === guards">
            <a matLine (click)="routingService.goToOperationManager(); onSidenavClose()">
              <mat-icon>build</mat-icon>
              <span>Operation</span>
            </a>
          </mat-list-item>

          <mat-list-item *ngIf="user.idRole === guards">
            <a matLine (click)="routingService.goToWarehouseManager(); onSidenavClose()">
              <mat-icon>store</mat-icon>
              <span>Magasin</span>
            </a>
          </mat-list-item>

          <mat-list-item *ngIf="user.idRole === guards">
            <a matLine (click)="routingService.goToFactoryManager(); onSidenavClose()">
              <i class="fas fa-industry"></i>
              <span>Usine</span>
            </a>
          </mat-list-item>

          <mat-list-item *ngIf="user.idRole === guards">
            <a matLine (click)="routingService.goToRepairCardManager(); onSidenavClose()">
              <mat-icon>description</mat-icon>
              <span>Modèle devis</span>
            </a>
          </mat-list-item>

        </mat-nav-list>

      </div>
  </div>
</perfect-scrollbar>
