import { Component, OnInit, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChangeDetectorRef } from '@angular/core';
import { forkJoin } from 'rxjs';
import { userConst } from 'src/app/shared/static/const/user-const';
import { environment } from 'src/environments/environment';
// Librairie
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
// Services
import { UsersService } from 'src/app/core/services/users/users.service';
import { OrderService } from 'src/app/core/services/orders/order.service';
import { SecurityService } from 'src/app/core/services/security/security.service';
// Model
import { User } from 'src/app/shared/models/user';
import { wsReturnCode } from '../../static/const/ws-return-code';

@Component({
  selector: 'app-connected-layout',
  templateUrl: './connected-layout.component.html',
  styleUrls: ['./connected-layout.component.scss']
})
export class ConnectedLayoutComponent implements OnInit, OnDestroy {

  userConnected: User;
  mobileQuery: MediaQueryList;
  opened: boolean;
  guards: number;
  version: string;
  versionBackend: string;
  toolTipVersion: string;
  private mobileQueryListener: () => void;
  
  // Gestion du désabonnement des observateurs
  private destroy$: Subject<void>;



  constructor(
    private usersService: UsersService,
    media: MediaMatcher,
    private snackBar: MatSnackBar,
    changeDetectorRef: ChangeDetectorRef,
    private orderService: OrderService,
    private securityService: SecurityService,
    private spinner: NgxSpinnerService,
  )
  {
    this.destroy$ = new Subject<void>();
    this.version = environment.version;
    this.mobileQuery = media.matchMedia(environment.const.menuMobileDisplay);
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  ngOnInit()
  {
    this.spinner.show("connectedLayoutSpinner");

    this.opened = false;
    this.guards = userConst.role.admin;

    const user = this.usersService.getUser();
    const userBasket = this.orderService.getBasket();

    if (!this.userConnected || !userBasket) {

    
      let getUserByToken = this.usersService.getUserByToken();
      let getBasket = this.orderService.getBasketForUser();
      let getBackVersion = this.securityService.getLastVersion();

      forkJoin([getUserByToken, getBasket, getBackVersion])
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        res => {
          this.userConnected = res[0];
          this.usersService.saveUser(res[0]);
          this.orderService.saveBasket(res[1]);
          if (res[2].status === wsReturnCode.success) {
            this.versionBackend = res[2].body.toString();
            this.toolTipVersion = "Frontend : " + this.version + " / Backend : " +  this.versionBackend 
          }
        },
        err => {
          this.snackBar.open
          (
            'Une erreur est survenue',
            'Femer',
            {duration: 3000}
          );
        }
      );
    }
  }

  /**
  * Procédure ngOnDestroy
  * Cette procédure vise à détruire l'ensemble des observateurs du composant
  */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
