// Commo
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Check access
import { AuthGuard } from 'src/app/core/guards/auth-guard';
import { AdminGuard } from 'src/app/core/guards/admin-guard';
// Layouts
import { ConnectedLayoutComponent } from 'src/app/shared/layouts/connected-layout/connected-layout.component';

const routes: Routes =
[

  {
    path: '',
    component: ConnectedLayoutComponent,
    canActivate: [AuthGuard],
    children:
    [
      {
        path: '',
        loadChildren: () => import('./modules/operation-space/dashboard/dashboard.module').then(mod => mod.DashboardModule),
      },
      {
        path: 'my-account',
        loadChildren: () => import('./modules/my-account/my-account.module').then(mod => mod.MyAccountModule),
      },
      {
        path: 'basket',
        loadChildren: () => import('./modules/basket/basket.module').then(mod => mod.BasketModule),
      },
      {
        path: 'search-part',
        loadChildren: () => import('./modules/operation-space/search-part/search-part.module').then(mod => mod.SearchPartModule),
      },
      {
        path: 'part',
        loadChildren: () => import('./modules/operation-space/select-part/select-part.module').then(mod => mod.SelectPartModule),
      },
      {
        path: 'quote',
        loadChildren: () => import('./modules/operation-space/quote/quote.module').then(mod => mod.QuoteModule),
      },
      {
        path: 'administration',
        canActivate : [AdminGuard],
        children:
        [
          {
            path: '',
            loadChildren: () => import('./modules/administration/dashboard/dashboard.module').then(mod => mod.DashboardModule)
          },
          {
            path: 'user',
            loadChildren: () => import('./modules/administration/users/users.module').then(mod => mod.UsersModule),
            data: {
              breadcrumb: 'Compte utilisateur',
              pathModule: 'administration/user'
            }
          },
          {
            path: 'cmm',
            loadChildren: () => import('./modules/administration/cmm/cmm.module').then(mod => mod.CmmModule)
          },
          {
            path: 'operation',
            loadChildren: () => import('./modules/administration/operation/operation.module').then(mod => mod.OperationModule)
          },
          {
            path: 'factory',
            loadChildren: () => import('./modules/administration/factory/factory.module').then(mod => mod.FactoryModule)
          },
          {
            path: 'reason',
            loadChildren: () => import('./modules/administration/reason/reason.module').then(mod => mod.ReasonModule)
          },
          {
            path: 'warehouse',
            loadChildren: () => import('./modules/administration/warehouse/warehouse.module').then(mod => mod.WarehouseModule)
          },
          {
            path: 'repair-card',
            loadChildren: () => import('./modules/administration/repair-card/repair-card.module').then(mod => mod.RepairCardModule)
          },
          {
            path: 'order',
            loadChildren: () => import('./modules/administration/order/order.module').then(mod => mod.OrderModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    children :
    [
      {
        path: '',
        loadChildren: () => import('./modules/security/security.module').then(mod => mod.SecurityModule)
      },
      {
        path: 'password',
        loadChildren: () => import('./modules/security/security.module').then(mod => mod.SecurityModule)
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ''
      },
      {
        path: '**',
        redirectTo: ''
      }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
