<ul *ngIf="user">
    <li *ngIf="!mobileQuery.matches">
      <a mat-button (click)="routingService.goToDashboard(user.idRole)">
        <mat-icon>home</mat-icon>
        <span>Accueil</span>
      </a>
    </li>
    <li *ngIf="!mobileQuery.matches">
      <a mat-button routerLink="/">
        <mat-icon>search</mat-icon>
        <span>Recherche pièce</span>
      </a>
    </li>
    <li *ngIf="!mobileQuery.matches && user.idRole === guards">
      <div class="menu-item">
        <app-bks-cmm-search></app-bks-cmm-search>
      </div>
    </li>
    <li *ngIf="!mobileQuery.matches">
      <a mat-button (click)="routingService.goToQuoteManager()">
        <mat-icon>assignment_returned</mat-icon>
        <span>Mes devis</span>
      </a>
    </li>
    <li *ngIf="!mobileQuery.matches">
        <a mat-button routerLink="/my-account">
          <div class="container-account">
            <mat-icon>account_circle</mat-icon>
            <ul class="container-account-information">
                <li *ngIf="user && !mobileQuery.matches">{{ user.firstname | uppercase }} {{ user.lastname | uppercase }}</li>
                <li>Gérer mon compte</li>
            </ul>
          </div>
        </a>
    </li>
    <li *ngIf="!mobileQuery.matches">
        <a mat-button (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
            <span>Se déconnecter</span>
        </a>
    </li>
  </ul>
 
