export const dataTechConst = {
    format: {
        base64: 'base64'
    },
    encoding: {
        utf8: 'charset=utf-8'
    },
    breakpoint: {
        mobile: '(max-width: 1230px)'
    }
};
